import React from 'react';
import catLogoLight from './catLight.svg';
import catLogoDark from './catDark.svg';
import RoomIcon from '@mui/icons-material/Room';
import './App.css';
import BrightnessSwitch from "./BrightnessSwitch";
import createPersistedState from 'use-persisted-state';


const useDarkState = createPersistedState('colorScheme');
const App = () => {
  const [dark, setDark] = useDarkState(false);

  return (
    <div className="App" data-theme={dark? 'dark': 'light'}>
      <BrightnessSwitch className={"brightness"} sx={{ m: 1 }} checked={dark}
                        onChange={(event) => setDark(event.target.checked)} />
      <header className="App-header">
        {dark ?
          <img src={catLogoDark} className="cat-logo" alt="logo-dark"/> :
          <img src={catLogoLight} className="cat-logo" alt="logo-light"/>}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}>
          <RoomIcon fontSize={"large"} style={{color: dark ? "778A86" : "#B53B54"}}/>
          <span className={"App-text"}>Champaign, IL</span>
        </div>
      </header>
      <main>
        <div className={"App-body"}>
          <h4>Welcome! I’m Sharon. I was born and raised in Hong Kong.</h4>
          <p>I love computers and developing connections with people even though I’m an introvert.  That’s why I’m doing a master’s in computer science at UIUC to explore ways to help people in academia make friends.</p>
          <p>Cantonese is my mother tongue, and I also speak Mandarin, English and German. Talking with people in their native language makes me happy, because I like making people feel comfortable and confident.</p>
          <p>My main programming languages are Javascript/Typescript, Python, and Java/Kotlin. Learn more about my profile here: <a href={'/resume.pdf'}>resume</a>.</p>
        </div>
      </main>
    </div>
  );
}

export default App;
